import React, { useEffect, useState } from "react";
import { Form, Offcanvas, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { useDeviceSelectors } from "react-device-detect";
import { Controller, useForm } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { FaRegThumbsDown } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import AutoSizedTextArea from "../../components/AutoSizeTextArea";
import { ActionButton, InlineLinkButton } from "../../components/Button/button";
import useBodyScrollLock from "../../hooks/useBodyScrollLock";
import { FeedbackScore } from "../../models/Chat.model";
import { submitFeedback } from "../../services/chat-services";
import "./../edit-page.scss";
import "./submit-feedback.scss";

type ChatProps = { chatId: string; responseId: string };

type ShowProps = ChatProps & {
  setShowPopover: (show: boolean) => void;
  showPopover: boolean;
};

const SubmitNegativeFeedback: React.FC<ChatProps> = ({ chatId, responseId }) => {
  const [showPopover, setShowPopover] = useState(false);

  useHotkeys(
    "Escape",
    () => {
      setShowPopover(false);
    },
    {
      enableOnTags: ["INPUT"],
    }
  );

  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);

  if (isMobileOnly) {
    return <SubmitNegativeFeedbackMobile chatId={chatId} responseId={responseId} />;
  }

  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement="top-start"
        overlay={
          <Popover id="submit-feedback-popover" className="shadow generic-popover">
            <Popover.Header className="d-flex">
              Обратна връзка
              {/* <HelpLink articleId={HELP_LINK_SAVESEARCH} /> */}
              <InlineLinkButton onClick={() => setShowPopover(false)} className="ms-auto">
                <IoMdClose />
              </InlineLinkButton>
            </Popover.Header>
            <Popover.Body>
              <SubmitFeedbackPopoverContent
                setShowPopover={setShowPopover}
                showPopover={showPopover}
                chatId={chatId}
                responseId={responseId}
              />
            </Popover.Body>
          </Popover>
        }
        show={showPopover}
        rootClose
        onToggle={(show) => {
          setShowPopover(show);
        }}
      >
        <span className="copy-button ms-0">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="submit-feedback-tooltip">Лош отговор</Tooltip>}
          >
            <span>
              <InlineLinkButton
                onClick={async () => {
                  setShowPopover(true);
                }}
              >
                <FaRegThumbsDown />
              </InlineLinkButton>
            </span>
          </OverlayTrigger>
        </span>
      </OverlayTrigger>
    </>
  );
};

const SubmitNegativeFeedbackMobile: React.FC<ChatProps> = ({ chatId, responseId }) => {
  const [showPopover, setShowPopover] = useState(false);

  useBodyScrollLock(showPopover);

  return (
    <>
      <Offcanvas
        show={showPopover}
        onHide={() => setShowPopover(false)}
        placement="bottom"
        backdrop
        id="submit-feedback-modal"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Обратна връзка</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SubmitFeedbackPopoverContent
            setShowPopover={setShowPopover}
            showPopover={showPopover}
            chatId={chatId}
            responseId={responseId}
          />
        </Offcanvas.Body>
      </Offcanvas>
      <OverlayTrigger
        placement="top"
        overlay={(props) => (
          <Tooltip id="bad-answer" {...props}>
            Лош отговор
          </Tooltip>
        )}
      >
        <span className="copy-button ms-0">
          <InlineLinkButton
            onClick={async () => {
              setShowPopover(true);
            }}
          >
            <FaRegThumbsDown />
          </InlineLinkButton>
        </span>
      </OverlayTrigger>
    </>
  );
};

const SubmitFeedbackPopoverContent: React.FC<ShowProps> = ({
  setShowPopover,
  showPopover,
  chatId,
  responseId,
}) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = useForm<{ score: FeedbackScore; comment: string }>({
    defaultValues: {
      score: "NEGATIVE",
      comment: "",
    },
  });

  useEffect(() => {
    !showPopover && reset();
  }, [showPopover, reset]);

  return (
    <Form className="edit-form">
      <Form.Group controlId="title">
        <Form.Label>Описание</Form.Label>
        <Controller
          name="comment"
          control={control}
          render={({ field: { onChange, value } }) => (
            <AutoSizedTextArea
              onChange={onChange}
              value={value}
              minHeight={100}
              maxHeight={200}
              autoFocus
            />
          )}
        />
      </Form.Group>

      <ActionButton
        onClick={handleSubmit(async (data) => {
          try {
            await submitFeedback(chatId, responseId, data);
            setShowPopover(false);

            toast.success("Благодарим за обратната връзка!");
          } catch (e) {
            console.log(e);
            toast.error("Грешка при запазване на обратната връзка!");
          }
        })}
        submitting={isSubmitting}
      >
        <FormattedMessage id="button.confirm" />
      </ActionButton>
    </Form>
  );
};

export default SubmitNegativeFeedback;
