import React, { useEffect, useRef } from "react";
import { Badge, OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";
import { ActionButton } from "../../components/Button/button";
import { useCurrentUserQuotas } from "../../services/user-services";
import "./gpt-input.scss";

type SearchInputProps = {
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  onSearch: () => void;
  submitting: boolean;
  newSearchEnabled: boolean;
  onClearSearch: () => void;
};

const SearchInputLexebraGPT: React.FC<SearchInputProps> = ({
  inputValue,
  setInputValue,
  onSearch,
  newSearchEnabled,
  submitting,
  onClearSearch,
}) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
      autoResizeTextarea();
    }
  }, [newSearchEnabled]);

  const autoResizeTextarea = () => {
    if (ref.current) {
      ref.current.style.height = "auto";
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  };

  const charLimit = 800;
  const getCharCounterClass = () => {
    if (inputValue.length >= charLimit) {
      return "danger";
    } else if (inputValue.length >= charLimit * 0.8) {
      return "warning";
    } else {
      return "primary";
    }
  };

  const getProgressBarClass = (available: number, limit: number) => {
    if (available === 0) {
      return "danger";
    } else if (available / limit <= 0.2) {
      return "warning";
    } else {
      return "success";
    }
  };

  const userQuotas = useCurrentUserQuotas();

  return (
    <div className="textarea-button-container">
      <div className="d-flex w-100" style={{ opacity: 0.7 }}>
        <textarea
          className="form-control"
          placeholder="Търсене по правен въпрос"
          ref={ref}
          value={inputValue}
          rows={2}
          onChange={(e) => {
            setInputValue(e.target.value?.substring(0, charLimit) || "");
            autoResizeTextarea();
          }}
          maxLength={charLimit}
          disabled={newSearchEnabled}
          style={{ resize: "none", overflow: "hidden" }}
        />
        {!!inputValue.length && !newSearchEnabled && (
          <Badge bg={getCharCounterClass()} style={{ height: "fit-content" }} className="me-2 mt-2">
            {inputValue.length}/{charLimit}
          </Badge>
        )}
      </div>

      <div className="d-flex gap-1 ps-3 w-100 flex-grow-0">
        {!newSearchEnabled ? (
          <ActionButton
            messageId=""
            disabled={inputValue.trim() === "" || submitting}
            onClick={onSearch}
            submitting={submitting}
          >
            Попитай Лексебра
          </ActionButton>
        ) : (
          <ActionButton messageId="" onClick={onClearSearch} submitting={false}>
            Нов въпрос
          </ActionButton>
        )}
        <div className=" flex-grow-1"></div>
        {userQuotas && !newSearchEnabled && (
          <div
            className="d-flex ps-3 pe-3 align-items-center gap-1 ms-auto  flex-grow-0"
            style={{ width: "200px" }}
          >
            <OverlayTrigger
              placement="top"
              overlay={(props) => (
                <Tooltip id="progress-tooltip" {...props}>
                  Налична месечна квота за въпроси
                </Tooltip>
              )}
            >
              <ProgressBar
                variant={getProgressBarClass(
                  userQuotas.availableGptCredits,
                  userQuotas.refillGptCredits
                )}
                now={userQuotas.availableGptCredits}
                max={userQuotas.refillGptCredits}
                label={`${Math.round(
                  (userQuotas.availableGptCredits * 100) / userQuotas.refillGptCredits
                )}%`}
                className="w-100"
              />
            </OverlayTrigger>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchInputLexebraGPT;
