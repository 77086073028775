import React, { useEffect, useState } from "react";
import "./loadingIndicator.css";

const loadingMessages = [
  "Анализиране на поставения въпрос",
  "Идентифициране на релевантни становища",
  "Анализиране на приложимата съдебна практика",
  "Оценка на евентуални противоречия",
  "Систематизиране на правния контекст и неговото значение",
  "Формулиране на обоснован правен извод",
  "Изготвяне на окончателен и аргументиран отговор",
];

const LoadingIndicator: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dotCount, setDotCount] = useState(1);
  const [dotDirection, setDotDirection] = useState<"up" | "down">("up");

  useEffect(() => {
    if (currentIndex < loadingMessages.length - 1) {
      const messageTimeout = setTimeout(() => {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 2000);

      return () => clearTimeout(messageTimeout);
    }
  }, [currentIndex]);

  useEffect(() => {
    const dotInterval = setInterval(() => {
      setDotCount((prevCount) => {
        if (dotDirection === "up") {
          if (prevCount < 3) {
            return prevCount + 1;
          } else {
            setDotDirection("down");
            return prevCount - 1;
          }
        } else {
          if (prevCount > 1) {
            return prevCount - 1;
          } else {
            setDotDirection("up");
            return prevCount + 1;
          }
        }
      });
    }, 500);

    return () => clearInterval(dotInterval);
  }, [dotDirection]);

  const displayDots = ".".repeat(dotCount);

  return (
    <span className="loading-message">
      {loadingMessages[currentIndex]}
      {displayDots}
    </span>
  );
};

export default LoadingIndicator;
