import { EntityModel } from "hateoas-hal-types";
import useRequest, { mutate, post, put } from "../api";
import { Chat, FeedbackScore } from "../models/Chat.model";
import RefData from "../models/RefData.model";

const CHATS_BASE_URL = "/chats";

export const useChats = (type: "recent" | "saved", query?: string) =>
  useRequest<EntityModel<Chat>[]>(
    `${CHATS_BASE_URL}/${type}${query ? `?query=${query}` : ""}`,
    (data) => data?._embedded?.items || []
  );

export const useChat = (id?: string) => {
  let { data } = useRequest<EntityModel<Chat>>(id ? `${CHATS_BASE_URL}/${id}` : null);
  return data;
};

export const startChat = async (data: { query: string; model: RefData }) => {
  const result = (await post(`${CHATS_BASE_URL}`, data)) as EntityModel<Chat>;
  await mutate(`${CHATS_BASE_URL}/recent`, () => {}, true);
  await mutate(`${CHATS_BASE_URL}/${result.id}`, () => result, false);
  await mutate(`/user/current/quotas`);
  return result;
};

export const regenerateResponse = async (chatId: string, data: RefData) => {
  const result = (await post(`${CHATS_BASE_URL}/${chatId}/responses`, data)) as EntityModel<Chat>;
  await mutate(`${CHATS_BASE_URL}/${chatId}`, () => result, false);
  await mutate(`/user/current/quotas`);
  return result;
};

export const markSaved = async (chatId: string, data: boolean) => {
  const result = (await put(`${CHATS_BASE_URL}/${chatId}/saved`, data)) as EntityModel<Chat>;
  await mutate(`${CHATS_BASE_URL}/${chatId}`, () => result, false);
  return result;
};

export const submitFeedback = async (
  chatId: string,
  responseId: string,
  data: { score: FeedbackScore; comment?: string }
) => {
  const result = (await put(
    `${CHATS_BASE_URL}/${chatId}/responses/${responseId}/feedback`,
    data
  )) as EntityModel<Chat>;
  await mutate(`${CHATS_BASE_URL}/${chatId}`, () => result, false);
  return result;
};
